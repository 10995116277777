import axios from 'axios'
import { _GLOBAL } from '@/api/server'
import secureStorage from '@/utils/secureStorage'

// create an axios instance
const service = axios.create({
  baseURL: _GLOBAL.URLBACKEND, // api 的 base_url
  timeout: 180000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    if(secureStorage.getItem('jwt') != null){
      // let token = secureStorage.getItem('jwt')
      // config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  error => {
    // Do something with request error
    // console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => response,

  error => {
    // console.log(error.message) // for debug
    var err = error.message
    // var log = err.includes("404")
    console.log(err) // for debug
    if(err.includes("401")){ //Authentication failed then logout
      console.log(err)
      
      console.log('401 go to login page') // for debug
      
      secureStorage.removeItem('user')
      secureStorage.removeItem('jwt')
      secureStorage.removeItem('userpos')
      secureStorage.removeItem('orgall')
      secureStorage.removeItem('userall')
      secureStorage.removeItem('userposall')
      secureStorage.removeItem('currRole')
      secureStorage.removeItem('posall')

      window.location = '/'
    }else if(!err.includes("404") && !err.includes("500")){
      /*Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })*/
    }
    return Promise.reject(error)
  }
)

export default service
